import { Injectable } from '@angular/core';
import { AuditStandards } from '../../../shared/constants/audit-standard';
import { ISuccessCriteria } from '../../../shared/audits/definitions/success-criteria/success-criteria.interface';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { TranslateService } from '../translate/translate.service';
import { SuccessCriteriaService } from './success-criteria.service';
import { $successCriteria } from '../../../shared/audits/definitions/success-criteria/constants';

export enum Separator {
  newLine = 'newLine',
  comma = 'comma',
}

export enum Divider {
  hyphen = 'hyphen',
}

export enum CriteriaPreset {
  // Displays WCAG and Not applicable success criterias by "num" format. (i.e. 1.1.1)
  criteriaIdentifier = 'criteriaIdentifier',
  // Displays WCAG and Not applicable success criterias by "level" format. (i.e. A)
  criteriaLevel = 'criteriaLevel',
  // Displays WCAG and Not applicable success criterias by "handle" format. (i.e. Non-text Content)
  criteriaHandle = 'criteriaHandle',
  // Displays WCAG and Not applicable success criterias by "num Level level" format. (i.e. 1.1.1 Level AA)
  criteriaIdentifierWithLevel = 'criteriaIdentifierWithLevel',
  // Displays WCAG and Not applicable success criterias by "num handle" format. (i.e. 1.1.1 - Non-text Content)
  criteriaIdentifierWithHandle = 'criteriaIdentifierWithHandle',
  // Displays WCAG and Not applicable success criterias as "num - handle - level" format. (i.e. 1.1.1 - Non-text Content - Level A)
  criteriaFull = 'criteriaFull',
  // Displays WCAG and Not applicable success criterias by "version" format. (i.e. 1.1.1)
  criteriaVersions = 'criteriaVersions',
}

interface IDisplaySuccessCriteria {
  standards?: AuditStandards[];
  identifiers: string[];
  preset: CriteriaPreset;
  separator?: Separator;
  divider?: Divider;
}

@Injectable({
  providedIn: 'root',
})
export class SuccessCriteriaFormatterService {
  private readonly DEFAULT_STANDARDS: AuditStandards[] = [
    AuditStandards.wcag,
    AuditStandards.section508,
    AuditStandards.en301549,
    AuditStandards.wcagCr,
  ];

  constructor(
    private translateService: TranslateService,
    private successCriteriaService: SuccessCriteriaService,
  ) {}

  /**
   * Formats success criterias by identifiers.
   * @param identifiers represents an array of identifiers i.e. ['1.1.1', 'Not applicable'].
   * @param formatter transforms the success criteria data to a desired format.
   * @param standards represents the standards associated with the identifier i.i. ['wcag'].
   * @private
   */
  private formatSuccessCriteria(
    identifiers: string[],
    formatter: (criteria: ISuccessCriteria) => string,
    standards?: AuditStandards[],
  ): string[] {
    const successCriterias: ISuccessCriteria[] = this.successCriteriaService.getSuccessCriteriaListFromStandards(
      identifiers.filter(Boolean),
      SharedCommonUtility.notNullishOrEmpty(standards) ? standards : this.DEFAULT_STANDARDS,
    );
    return successCriterias.map(formatter);
  }

  private handleDivider(divider: Divider): string {
    let _divider: string = ' ';

    if (divider === Divider.hyphen) {
      _divider = ' - ';
    }

    return _divider;
  }

  private handleSeparator(separator: Separator): string {
    let _separator: string = '';

    if (separator === Separator.newLine) {
      _separator = '\n';
    } else if (separator === Separator.comma) {
      _separator = ', ';
    }

    return _separator;
  }

  /**
   * Displays success criterias based on the configured presets and separator.
   * @param standards represents the standard associated with the success criteria.
   * @param identifiers represents success criteria identifier values.
   * @param preset {CriteriaPreset} represents success criteria preset or format.
   * @param separator {Separator} represents separator for success criteria values.
   * @param divider {Divider} represents divider between success criteria properties.
   */
  public toDisplayCriterias({ standards, identifiers, preset, separator, divider }: IDisplaySuccessCriteria): string {
    let criterias: string[] = [];
    const _divider: string = this.handleDivider(divider);

    if (preset === CriteriaPreset.criteriaIdentifier) {
      criterias = this.formatSuccessCriteria(
        identifiers,
        (criteria: ISuccessCriteria): string => `${criteria[$successCriteria.num]}`,
        standards,
      );
    }

    if (preset === CriteriaPreset.criteriaLevel) {
      criterias = this.formatSuccessCriteria(
        identifiers,
        (criteria: ISuccessCriteria): string => `${criteria[$successCriteria.level]}`,
        standards,
      );
    }

    if (preset === CriteriaPreset.criteriaHandle) {
      criterias = this.formatSuccessCriteria(
        identifiers,
        (criteria: ISuccessCriteria): string => `${criteria[$successCriteria.handle]}`,
        standards,
      );
    }

    if (preset === CriteriaPreset.criteriaIdentifierWithHandle) {
      criterias = this.formatSuccessCriteria(
        identifiers,
        (criteria: ISuccessCriteria): string =>
          `${criteria[$successCriteria.num]}${_divider}${criteria[$successCriteria.handle]}`,
        standards,
      );
    }

    if (preset === CriteriaPreset.criteriaIdentifierWithLevel) {
      criterias = this.formatSuccessCriteria(
        identifiers,
        (criteria: ISuccessCriteria): string => {
          const level: string = SharedCommonUtility.notNullishOrEmpty(criteria[$successCriteria.level])
            ? ` - ${this.translateService.instant('level')} ${criteria.level}`
            : '';
          return `${criteria[$successCriteria.num]}${level}`;
        },
        standards,
      );
    }

    if (preset === CriteriaPreset.criteriaFull) {
      criterias = this.formatSuccessCriteria(
        identifiers,
        (successCriteria: ISuccessCriteria): string => {
          const level: string = SharedCommonUtility.notNullishOrEmpty(successCriteria[$successCriteria.level])
            ? ` - ${this.translateService.instant('level')} ${successCriteria.level}`
            : '';

          return `${successCriteria[$successCriteria.num]}${_divider}${successCriteria[$successCriteria.handle]}${level}`;
        },
        standards,
      );
    }

    if (preset === CriteriaPreset.criteriaVersions) {
      criterias = this.formatSuccessCriteria(identifiers, (wcag: ISuccessCriteria): string => `${wcag.versions}`, standards)
        .join('\n')
        .split(',');
    }

    return criterias.filter(Boolean).join(this.handleSeparator(separator));
  }

  public getAccessibilityStandards(ruleCriterias: Map<AuditStandards, ISuccessCriteria[]>): string[] {
    const formattedStandards: string[] = [];

    for (const standard of ruleCriterias.keys()) {
      const crit: ISuccessCriteria[] = ruleCriterias.get(standard);
      const levelByVersions: Record<string, string[]> = crit.reduce(
        (acc: Record<string, string[]>, current: ISuccessCriteria): Record<string, string[]> => {
          current.versions.forEach((version: string): void => {
            if (current[$successCriteria.level]) {
              acc[version] = [...new Set([...(acc[version] ?? []), current[$successCriteria.level]])];
            }
          });
          return acc;
        },
        {},
      );
      const versions: string[] = Object.keys(levelByVersions);
      if (versions.length > 0) {
        versions.forEach((version: string) => {
          formattedStandards.push(`${standard} ${version} ${levelByVersions[version].join(', ')}`);
        });
      } else {
        formattedStandards.push(standard);
      }
    }
    return formattedStandards;
  }
}
